<template>
  <b-card-code>
<!--    <loader/>-->
    <div class="mb-2" v-if="institute">
      <img :src="imgShow(institute.logo)"  alt="logo" class="img-fluid"/>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="institute_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Institute Name')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="institute name"
                  rules="required"
              >
                <b-form-input
                    v-model="institute.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Write institute name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Institute Logo')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="logo"
                  :rules="institute.id?'':'required'"
              >
                <b-form-file
                    v-model="logo"
                    name="logo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a logo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="4" v-if="$can('update','Institute')">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
//import Loader from "@/views/Loader";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,BFormFile,
  BForm,BRow, BCol,BCardText
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
export default {
  name:'Institute',
  components: {
    BCardCode,
    BFormGroup,
    BFormInput,BCardText,
    BButton,BFormFile,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,//Loader
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      logo:null,
      required,
      pageLength: 5,
      dir: false,
    }
  },
  methods:{
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.institute_form);
          apiCall.post('/institute/information/storeOrUpdate',data).then((response)=>{
            this.$store.dispatch('GET_ALL_INSTITUTE');
            this.logo=null;
            this.$refs.simpleRules.reset();
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['institute']),
  },
  created() {
    this.$store.dispatch('GET_ALL_INSTITUTE');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>